@import 'assets/scss/variables.module.scss';

.container {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 2rem;
}

.content {
  max-width: 1200px;

  @include response-large {
    max-width: 90%;
    padding: 1rem;
  }
}

.blurb {
  font-size: 16px;
  margin-bottom: 2rem;
}

.codeBlock {
  color: $code-font-color;
  background-color: $light-background2;

  padding: 1.5rem;
  border-radius: 3px;

  box-shadow: $generic-box-shadow;
  white-space: pre-wrap;

  margin-bottom: 1rem;

  position: relative;
  overflow: auto;

  @include response-small {
    padding: 10px;
  }
}

.errorMessage {
  margin-bottom: 1rem;
  font-size: 16px;
}

.discordLink {
  background-color: #5865f2;
  color: white;
  font-weight: 700;

  transition: filter 0.2s ease;

  border-radius: 5px;
  padding: 0.57rem;

  &:hover {
    filter: brightness(1.25);
    color: white;
  }
}

.helpLinks {
  @include flex-center;
}

.forumLink {
  background-color: $button-grey;
  color: $button-grey-text-color;
  font-weight: 700;

  transition: filter 0.2s ease;

  border-radius: 5px;
  padding: 0.57rem;

  &:hover {
    filter: brightness(1.25);
    color: $button-grey-text-color;
  }
}

.copyButton {
  color: $color;
  background: none;
  border: none;

  position: absolute;
  bottom: 10px;
  right: 10px;

  transition: filter 0.2s ease;

  &:hover:enabled {
    cursor: pointer;
    filter: brightness(1.1);
  }
}
