@import 'assets/scss/variables.module';

.container{
  position: fixed;
  bottom: 200px;
  right: 15px;
  z-index: 8;
  width: 300px;
  height: 160px;
  //background-color: rgba(0, 0, 0, 0.5); // for testing visibility purposes
  @include response-small {
    bottom: 60px;
    width: 160px;
    height: 90px;
  }
}

.innerContainer {
  position: relative;
}

.closeButton {
  background: transparent;
  border: none;
  cursor: pointer;
  i {
    color: $light-color;
  }
  text-shadow: 4px 3px 8px rgba(0,0,0,0.6);
  font-size: 1.5rem;
  position: absolute;
  top: 0;
  left: 0;
}