@import 'assets/scss/variables.module';

.options {
  width: 215px;
}

.optionsMenuTrigger {
  width: 20px;

  display: flex;
  justify-content: flex-end;

  i {
    margin-right: 0 !important;
  }
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;

  position: relative;

  margin-bottom: 1px;
}

.dropdown {
  display: flex;
}

.meta {
  display: flex;
  justify-content: space-between;
  width: 100%;

  color: $light-color;
  font-size: 12px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  h4 {
    margin: 0;

    width: 100%;
    max-width: 80%;
    display: flex;
  }
}

.headerName {
  flex: 0 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  gap: 3px;
}

.stackMenuTrigger {
  background: transparent;
  color: $color;
  border: none;

  i {
    margin: 0 !important;
  }

  &:hover:enabled {
    cursor: pointer;
  }
}

.title {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.strikethrough {
  text-decoration: line-through;
}

.outOfDeck {
  color: $light-color;
}
